<template>
	<div>
        <a href=""><img class="logo" src="/images/admin/logo.png" alt="관리자로고"/></a>
        <div class="main-contents">
            <div class="login-box">
                <img src="/images/admin/login.png" alt="관리자로그인"/>
                <div class="loginform">
                    <div class="inputlist"><span>*</span> 아이디<InputComp v-model="id" @keyup.enter="login()" /></div>
                    <div class="inputlist"><span>*</span> 비밀번호<InputComp type="password" v-model="password" @keyup.enter="login()" /></div>
                    <SelectComp tagName="div" class="inputlist" type="yn" title="로그인 상태 유지" v-model="keepLoginYn"/>
                    <div class="login-btn" @click="login()">로그인</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {
			id: "",
			password: "",
			keepLoginYn: '',
		};
	},

	methods : {
		login() {
			this.$.login(this.id, this.password, this.keepLoginYn)
				.then((res) => {
					if(res.data.mberDivCd.substring(0, 1) != 0) {
						alert('관리자만 접속할 수 있습니다.');
						this.$.logout('/');
					}else {
						this.$router.move('/admin', false);
						//location.href = "/admin";
					}
				})
				.catch(this.$.httpErrorCommon);
		},
	}
}
</script>